.root li:hover{
  color: var(--color) !important;
  cursor: pointer;
}
.root span{
  margin-left: 10px;
}
.root span {
  color: #d9534f ;
}
.root span:hover{
  cursor: pointer;
  text-decoration: underline;
}