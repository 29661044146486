.root {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.root > div {
  flex: 0.5 200px;
  max-width: 400px;
}

.root p {
  color: #1a1a1a
}