.root {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.layer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.fon{
  background-color: #1a1a1a;
  opacity: 0.8;
}