.root{
  padding:20px 30px;
  border: 1px solid var(--color);
  max-width: 1000px;
}
.root h3,
.root li,
.root legend {
  color: var(--color);
}

.root li{
   padding: 5px;
}

.root li:hover{
  cursor: pointer;
  text-decoration: underline;
}