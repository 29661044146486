.root>p{
  opacity: 0.7;
  color: var(--color)
}
.root>p:hover{
  text-decoration: underline;
  cursor: pointer;
  opacity: 1;
}
.root>p img{
  width: 25px;
  margin-right: 15px;
}
.root textarea {
  border: solid 1px var(--color);
}