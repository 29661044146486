.root {
  margin: 20px;
}

.root>h1,
.root>h2,
.root>h3,
.root>h4,
.root>h5,
.root>h6 {
  color: var(--color);
}