.root {
  z-index: 100500;
  display: flex;
  flex-flow: row wrap !important;
  justify-content: center;
  /* align-items: start; */
}
.root span{
  cursor: pointer !important;
}

.root nav{
  z-index: 100500;
}
.root div:nth-child(1){
  flex: 12;
}
.root div:nth-child(2){
  margin-top: 20px;
  flex: 1;
}
.root img{
  width: 8em;
}

.root nav {
  padding: 0 !important;
}