.root {
  background-color: #1a1a1a;
  bottom: 0px;
  min-height: 250px;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  /* align-items: center; */
}

.root > div {
  flex: 0.5 200px;
  margin: 30px 20px 0px 20px;
}

.root > div:nth-child(2) li {
  list-style-type: none;
}

.root h1,
.root h2,
.root h3,
.root h4,
.root h5,
.root h6,
.root p,
.root li,
.root a {
  color: rgb(235, 235, 235);
  opacity: 0.8;
}

.root a:hover {
  color: rgb(235, 235, 235);
  opacity: 1;
}