.root {
  text-align: center;
}

.root p {
  color: var(--color);
}

.root span {
  cursor: pointer;
  text-decoration: underline;
}
