.root {
  display: flex;
  flex-direction: column;
  padding:20px 30px;
  border: 1px solid var(--color);
  max-width: 1000px;
}
.root p,
.root h1,
.root h2,
.root h3,
.root h4,
.root h5,
.root h6 {
  color: var(--color)
}

.root a:hover{
  color: var(--color) !important;
}


.root h4:hover {
  text-decoration: underline;
  cursor: pointer;
}

.root>p {
  margin-left: 10px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* max-height: 249px;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.content {
  display: flex;
}

.svgImage {
  width: 150px;
  height: 110px;
  margin-right: 10px;
}

.svgImage path {
  fill: var(--color);
}

.svgIcon {
  display: flex;
  align-items: center;
}

.svgButton {
  margin-right: 10px;
  fill: var(--color);
}

.sliceMessage a {
  text-decoration: none;
  margin-left: 5px;
  color: var(--color-link);
}

.sliceMessage a :hover {
  color: var(--color) !important;
}

.sliceMessage p {
  max-width: 750px;
}

.foto {
  margin-right: 10px;
  max-width: 150px;
  max-height: 150px;
}

.isPublic {  
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}