.root {
  position: relative;
  height: 21em;
  overflow: hidden;
  width: 100%;
}

.slidesWrapper{
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  transition: all 0.5s ease-out;
}
