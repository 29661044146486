.root{
  padding:20px 30px;
  border: 1px solid var(--color);
  max-width: 1000px;
}

.root h3,
.root li,
.root legend {
  color: var(--color);
}

.root input[type=submit] {
  margin-right: 10px;
}

.formUser {
  display: flex;
  flex-direction: row;
}

.isPablick {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.isPablick input {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.isPablick label {
  color: var(--color);
}
