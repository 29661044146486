.root{
  margin: 35px 0 35px 0;
}

.svg {
  position: relative;
  left: -25px;
  background-color: var(--background-color);
  opacity: 1 !important;
  /* fill: var(--color); */
  fill: rgb(45, 115, 69); 
  opacity: 0.6;
}

/* .root>div{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.root>div>div{
  flex: 2;
}

.sign {
  color: rgb(45, 115, 69);
  background-color: #dbf2e3;
  padding: 15px;
  width: auto;
} */
