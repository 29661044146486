.isPablick {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.isPablick input {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.isPablick label {
  color: var(--color);
}
