.root {
  /* width: var(--slide-width); */
  position: relative;
  transition: all  0.5s ease-out;
  background-color: var(--background-color);
  padding: 10px;
}

.wrapper {
  border: solid 1px var(--color);
  height: 100%;
  overflow: hidden;
  padding: 10px;
}

.root img{
  height: 9em;
  margin-bottom: 10px;
  /* width: 80%; */
}

.root>div:nth-child(1) {
  text-align: center;
}