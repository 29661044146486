.root {
  z-index: 100500;
  text-align: right;
  margin: 10px 10px 0 10px;
}
/* 
*, *:after, *:before {
    box-sizing: border-box;
  } */
  
  /*=====================*/
  .checkbox {
    position: relative;
    display: inline-block;
  }

  .checkbox:after, .checkbox:before {
    /* font-family: FontAwesome;
    font-feature-settings: normal;
    -webkit-font-kerning: auto;
            font-kerning: auto;
    font-language-override: normal;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
    font-variant: normal;
    font-weight: normal;
    text-rendering: auto; */
  }
  .checkbox label {
    width: 40px;
    height: 42px;
    /* background: #ccc; */
    position: relative;
    /* display: inline-block; */
    border-radius: 100px;
    /* transition: 0.4s; */
  }
  .checkbox label:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    left: 0;
    /* top: 0px; */
    /* z-index: 2; */
    /* background: #fff; */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
  }
  .checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }
  .checkbox input:hover + label:after {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
  }
  .checkbox input:checked + label:after {
    /* left: 40px; */
  }
  
  .model .checkbox label {
    background: none;
    border: 1px solid #555;
    height: 17px;
  }
  .model .checkbox label:after {
    background: #555;
    box-shadow: none;
    top: 1px;
    left: 1px;
    width: 12px;
    height: 12px;
  }
  .model .checkbox input:checked + label {
    /* border-color: #ffffff; */
  }
  .model .checkbox input:checked + label:after {
    /* background: #ffffff; */
    left: 25px;
  }  