.root{
  display: flex;
  flex-flow: row nowrap;
  /* align-items: center; */
}

.root>div{
  flex: 2;
}

.root>div:nth-child(2) {
  text-align: right;
}
